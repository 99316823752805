define("itarp-landing-frontend/controllers/signup/client", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency", "itarp-landing-frontend/utils/validation"], function (_exports, _controller, _tracking, _object, _service, _emberConcurrency, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SignupClientController = (_dec = (0, _emberConcurrency.task)(function* (term) {
    if (term.length <= 3) {
      return [];
    }
    yield (0, _emberConcurrency.timeout)(1000);
    let url = `/api/companies?filter[name][$regex]=${term}&filter[name][$options]=i&per_page=infinity`;
    return fetch(url).then(resp => resp.json()).then(json => {
      this.companies = json.records.map(record => record.name);
    });
  }), (_class = class SignupClientController extends _controller.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['email', 'company_name']);
      _initializerDefineProperty(this, "email", _descriptor, this);
      _initializerDefineProperty(this, "company_name", _descriptor2, this);
      _initializerDefineProperty(this, "companies", _descriptor3, this);
      _initializerDefineProperty(this, "auth", _descriptor4, this);
      _initializerDefineProperty(this, "isIndividual", _descriptor5, this);
      _initializerDefineProperty(this, "errors", _descriptor6, this);
      _initializerDefineProperty(this, "fieldError", _descriptor7, this);
      _initializerDefineProperty(this, "isSignUpInProgress", _descriptor8, this);
      _initializerDefineProperty(this, "inputTypes", _descriptor9, this);
      _initializerDefineProperty(this, "client", _descriptor10, this);
      _initializerDefineProperty(this, "userEmail", _descriptor11, this);
      _initializerDefineProperty(this, "searchCompanies", _descriptor12, this);
    }
    trimEmail(event) {
      this.userEmail = event.target.value.trim();
    }
    updateUser(property, event) {
      if (property === 'accept_terms') {
        this.client.users[property] = !this.client.users[property];
      } else {
        this.client.users[property] = event.target.value;
      }
    }
    updateIdentity(property, event) {
      this.client.identity[property] = event.target.value;
    }
    validateFields() {
      const {
        first_name,
        last_name,
        companyName,
        accept_terms
      } = this.client.users;
      const {
        key,
        confirm_password
      } = this.client.identity;
      const user = {
        first_name,
        last_name,
        companyName,
        key,
        confirm_password,
        accept_terms,
        email: this.userEmail
      };
      const {
        validationResult,
        fieldError
      } = (0, _validation.validateRequiredFields)(user, {
        isSignIn: false,
        isClient: true
      });
      if (user.email && !(0, _validation.validateEmail)(user.email)) {
        fieldError['email'] = true;
        validationResult['email'] = 'Email is not valid';
      }
      if (user.first_name && !(0, _validation.validateName)(user.first_name)) {
        fieldError['first_name'] = true;
        validationResult['first_name'] = 'First Name cannot contain special characters';
      }
      if (user.last_name && !(0, _validation.validateName)(user.last_name)) {
        fieldError['last_name'] = true;
        validationResult['last_name'] = 'Last Name cannot contain special characters';
      }
      if (user.confirm_password && !(0, _validation.passwordsMatch)(user.key, user.confirm_password)) {
        fieldError['key'] = true;
        fieldError['confirm_password'] = true;
        validationResult['confirm_password'] = 'New Password and Confirm Password do not match';
      }
      this.fieldError = fieldError;
      Object.keys(user).forEach(key => {
        if (validationResult[`${key}`]) {
          this.errors.push(validationResult[`${key}`]);
        }
      });
      return this.errors.length === 0;
    }
    createUser() {
      this.errors = [];
      if (this.validateFields()) {
        this.isSignUpInProgress = true;
        this.client.users.user_type = 'tod_client';
        const data = JSON.stringify({
          users: {
            ...this.client.users,
            email: this.userEmail
          },
          identity: this.client.identity
        });
        try {
          fetch(`/api/users`, {
            method: 'POST',
            body: data,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            return res.json();
          }).then(json => {
            if (json.access_token) {
              this.auth.token = json.access_token;
              this.transitionToRoute('landing-page');
            } else {
              const error = json.error || '';
              if (error) {
                const errorMessage = error.split(',')[1];
                if (errorMessage) {
                  this.errors = [...this.errors, errorMessage.split('=>')[1].replace('"', '')];
                }
              }
              const errors = json.errors || [];
              if (errors.length) {
                const errorMessage = errors[0];
                this.errors = [...this.errors, errorMessage];
              }
            }
          }).catch(error => {
            this.errors = [...this.errors, error.toString().split(':')[1]];
            this.isSignUpInProgress = false;
          }).finally(() => {
            this.isSignUpInProgress = false;
          });
        } catch (error) {
          this.errors = [...this.errors, error.toString().split(':')[1]];
          this.isSignUpInProgress = false;
        }
      }
    }
    toggleInputType(property) {
      this.inputTypes = {
        ...this.inputTypes,
        [property]: this.inputTypes[property] === 'password' ? 'text' : 'password'
      };
    }
    clearCompanyOptions() {
      this.companies = [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "email", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "companies", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isIndividual", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fieldError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSignUpInProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "inputTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        key: 'password',
        confirm_password: 'password'
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "client", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        users: {
          first_name: '',
          last_name: '',
          user_type: '',
          companyName: this.company_name ? decodeURI(this.company_name) : '',
          accept_terms: false
        },
        identity: {
          type: 'password',
          key: '',
          confirm_password: ''
        }
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "userEmail", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "trimEmail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "trimEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIdentity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateIdentity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInputType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInputType"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "searchCompanies", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearCompanyOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearCompanyOptions"), _class.prototype)), _class));
  _exports.default = SignupClientController;
});